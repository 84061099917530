// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/PWn1k_MD2";
import * as localizedValues from "./a7w3v2CCa-0.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {AY2iJjz0z: {hover: true}};

const cycleOrder = ["pulFlqedB", "TuI1FkLhZ", "AY2iJjz0z"];

const serializationHash = "framer-9nkDv"

const variantClassNames = {AY2iJjz0z: "framer-v-162iq3o", pulFlqedB: "framer-v-1wdy4ks", TuI1FkLhZ: "framer-v-baarrv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {Zx6v6ZVYd: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {"Space Around": "space-around", "Space Between": "space-between", "Space Evenly": "space-evenly", Center: "center", End: "flex-end", Start: "flex-start"}

const humanReadableVariantMap = {Closed: "TuI1FkLhZ", Open: "pulFlqedB", Simple: "AY2iJjz0z"}

const getProps = ({click2, color, distribute, height, id, link, title, width, ...props}) => { return {...props, Dl6AiX37G: color ?? props.Dl6AiX37G ?? "var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77))", H1TB4TUDH: click2 ?? props.H1TB4TUDH, kcKdHX2wm: title ?? props.kcKdHX2wm ?? "Features", NKudH2mEX: link ?? props.NKudH2mEX, rNFYlFoaT: humanReadableEnumMap[distribute] ?? distribute ?? props.rNFYlFoaT ?? "center", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pulFlqedB"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;color?: string;link?: string;distribute?: keyof typeof humanReadableEnumMap;click2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, kcKdHX2wm, Dl6AiX37G, NKudH2mEX, rNFYlFoaT, H1TB4TUDH, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "pulFlqedB", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1fu6v2j = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (H1TB4TUDH) {const res = await H1TB4TUDH(...args);
if (res === false) return false;}
})

const onClick1jfbi8x = activeVariantCallback(async (...args) => {
setVariant("vZNkcdFXx")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "AY2iJjz0z-hover") return false
if (baseVariant === "AY2iJjz0z") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={NKudH2mEX}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1wdy4ks", className, classNames)} framer-roi0j8`} data-framer-name={"Open"} data-highlight layoutDependency={layoutDependency} layoutId={"pulFlqedB"} onMouseEnter={onMouseEnter1fu6v2j} ref={ref ?? ref1} style={{"--1g9wuve": rNFYlFoaT, ...style}} {...addPropertyOverrides({"AY2iJjz0z-hover": {"data-framer-name": undefined}, AY2iJjz0z: {"data-framer-name": "Simple"}, TuI1FkLhZ: {"data-framer-name": "Closed"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1cc56zh"} data-styles-preset={"PWn1k_MD2"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-Dl6AiX37G-a7w3v2CCa))"}}>Features</motion.p></React.Fragment>} className={"framer-b7fp7k"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"mHiSIHdfP"} style={{"--extracted-r6o4lv": "var(--variable-reference-Dl6AiX37G-a7w3v2CCa)", "--variable-reference-Dl6AiX37G-a7w3v2CCa": Dl6AiX37G}} text={kcKdHX2wm} verticalAlignment={"top"} withExternalLayout/>{isDisplayed() && (<motion.div className={"framer-jyo5yj"} layoutDependency={layoutDependency} layoutId={"fLarbH1fh"} style={{rotate: 0}} variants={{TuI1FkLhZ: {rotate: -180}}}><ComponentViewportProvider ><motion.div className={"framer-16uib3x-container"} layoutDependency={layoutDependency} layoutId={"ay1YqJbqw-container"}><Phosphor color={Dl6AiX37G} height={"100%"} iconSearch={getLocalizedValue("v0", activeLocale) ?? "House"} iconSelection={"CaretDown"} id={"ay1YqJbqw"} layoutId={"ay1YqJbqw"} mirrored={false} onClick={onClick1jfbi8x} selectByList style={{height: "100%", width: "100%"}} weight={"bold"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div>)}</motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9nkDv.framer-roi0j8, .framer-9nkDv .framer-roi0j8 { display: block; }", ".framer-9nkDv.framer-1wdy4ks { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: var(--1g9wuve); overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-9nkDv .framer-b7fp7k { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-9nkDv .framer-jyo5yj { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 12px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 12px; }", ".framer-9nkDv .framer-16uib3x-container { flex: none; height: 12px; position: relative; width: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9nkDv.framer-1wdy4ks, .framer-9nkDv .framer-jyo5yj { gap: 0px; } .framer-9nkDv.framer-1wdy4ks > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-9nkDv.framer-1wdy4ks > :first-child, .framer-9nkDv .framer-jyo5yj > :first-child { margin-left: 0px; } .framer-9nkDv.framer-1wdy4ks > :last-child, .framer-9nkDv .framer-jyo5yj > :last-child { margin-right: 0px; } .framer-9nkDv .framer-jyo5yj > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } }", ".framer-9nkDv.framer-v-162iq3o.framer-1wdy4ks { cursor: pointer; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 81.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"TuI1FkLhZ":{"layout":["auto","auto"]},"AY2iJjz0z":{"layout":["auto","auto"]},"GF2iy3eg1":{"layout":["auto","auto"]}}}
 * @framerVariables {"kcKdHX2wm":"title","Dl6AiX37G":"color","NKudH2mEX":"link","rNFYlFoaT":"distribute","H1TB4TUDH":"click2"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framera7w3v2CCa: React.ComponentType<Props> = withCSS(Component, css, "framer-9nkDv") as typeof Component;
export default Framera7w3v2CCa;

Framera7w3v2CCa.displayName = "Nav/Menu";

Framera7w3v2CCa.defaultProps = {height: 24, width: 81.5};

addPropertyControls(Framera7w3v2CCa, {variant: {options: ["pulFlqedB", "TuI1FkLhZ", "AY2iJjz0z"], optionTitles: ["Open", "Closed", "Simple"], title: "Variant", type: ControlType.Enum}, kcKdHX2wm: {defaultValue: "Features", displayTextArea: false, title: "Title", type: ControlType.String}, Dl6AiX37G: {defaultValue: "var(--token-32e77181-d409-402b-aa60-c4d3d5f9d57d, rgb(77, 77, 77)) /* {\"name\":\"Text / Subtle\"} */", title: "Color", type: ControlType.Color}, NKudH2mEX: {title: "Link", type: ControlType.Link}, rNFYlFoaT: {defaultValue: "center", options: ["flex-start", "center", "flex-end", "space-between", "space-around", "space-evenly"], optionTitles: ["Start", "Center", "End", "Space Between", "Space Around", "Space Evenly"], title: "Distribute", type: ControlType.Enum}, H1TB4TUDH: {title: "Click 2", type: ControlType.EventHandler}} as any)

addFonts(Framera7w3v2CCa, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})